import "./card.css"

function Card({title, url, desc, img}) {
  return (
    <a href={url}>
        <div className="card ">
            <img src={img} />
            <div className="intro">
                <h1>{title}</h1>
                <p>{desc}</p>
            </div>
        </div>
    </a>
  );
}

export default Card;
