

function Links({}) {
  return (
    <div>
        <h1>Logiciels recommandés</h1>
        <h2>Général</h2>
        <ul>
            <li><b>Ditto</b>  : Presse-papier avec historique</li>
            <li>Keepass</li>
            <li>GreenShot</li>
            <li>Agent Ransack</li>
            <li>WizTree</li>
            <li>Paint.net</li>

        </ul>

        <h2>Développement</h2>
        <ul>
            <li>Notepad++</li>
            <li>Fork</li>
        </ul>
    </div>
  );
}

export default Links;