import "./button.css"

function Button({title, url}) {
  return (
    <div className="container">
      <a href={url} className="button">{title}</a>
    </div>
  );
}

export default Button;
