import "./homepage.css"

import Stack from "@mui/material/Stack"

import TaskyImg from "../Tasky/img/Tasky2.png"
import RubikTeacherImg from "../RubikTeacher/img/RubikTeacher4.png"
import StackmatTimeryImg from "../VirtualStackmatTimer/img/virtualStackmat2.png"

import Card from "./Card/Card"


function HomePage() {
  return (
    <div>
      <p>
        Vous trouverez sur ce site: 
        <ul>
          <li>mes logiciels perso en libre accès</li>
          <li>des méthodes pour apprendre à résoudre le Rubik's Cube facilement</li>
          </ul>
      </p>
      <h1>Mes Logiciels</h1>
      <h2>Organisation</h2>
      <Stack direction="row" spacing={2}>
		    <Card title="Tasky" url="/Tasky" img={TaskyImg} desc="Gestionnaire de tâches intuitif permettant aussi de programmer des rappels, d'organiser des liens Internet ou de fichiers, des mots de passe, des notes, des contacts, lancer des scripts..." />
      </Stack>

        <h2>Rubik's Cube</h2>
    	 <Stack direction="row" spacing={2}>
        <Card title="Rubik Teacher" url="/RubikTeacher" img={RubikTeacherImg} desc="Résolvez votre Rubik's Cube grâce à ce logiciel et apprenez à le résoudre facilement et rapidement!" />
        <Card title="Virtual Stackmat" url="/RubikTeacher" img={StackmatTimeryImg} desc="Chronométrez votre temps de résolution du Rubik's Cube et suivez vos progrès" />
      </Stack>
    </div>
  );
}

export default HomePage;
