import React, { useEffect } from 'react';

import Stack from "@mui/material/Stack"

import L_01 from "./img/L_01_.png"
import L_02 from "./img/L_02_.png"
import L_03 from "./img/L_03_.png"
import L_04 from "./img/L_04_.png"
import L_05 from "./img/L_05.png"


import './rubikbeginner.css'

function useScript(url) {
	useEffect(() => {
	  //console.log(url);
	  const script = document.createElement('script');
  
	  script.src = url;
	  script.async = true;
	  script.type = "text/javascript"
  
	  document.head.appendChild(script);
  
	  return () => {
		document.head.removeChild(script);
	  }
	}, [url]);
  };

function RubikBeginner() {

	useScript("http://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js")
useScript("https://www.recubix.fr/backup/Rubik/Fridrich/roofpig_and_three.min.js")


  return (
    <div>
		 <h1>Méthode de résolution du Rubik's Cube Débutant : Couche par Couche</h1>


		<div>
			<em>
				Un peu d'histoire avant tout : <br />
				Inventé par Erno Rubik en 1974 qui mit lui-même plus d'un mois avant de résoudre le sien, le Rubik's Cube est un casse-tête mondialement réputé pour sa difficulté.<br />
				En effet, il existe plus de 43 milliards des milliards de combinaisons différentes, dont 1 seule correspond au cube fini. <br/>
				Sachez cependant que quelle que soit le mélange du cube, celui-ci peut toujours être (théoriquement) résolu en 23 mouvements maximum. <br/>
				Pour ceux qui, du coup, sous-estimant la difficulté, voudraient essayer de le résoudre par eux-mêmes, en tournant les faces jusqu'à arriver à un cube résolu, sachez qu'il vous faudrait plus de 1200 ans pour tester toutes les combinaisons.. a raison de 1 milliard de combianaisons testées par seconde...!

				Vous relevez le défi ? Envie de résoudre enfin ce cube et reconstituer ses 6 faces d'une même et unique couleur ? (Oui, au fait, c'est le but du jeu !)<br />
			</em>
		</div>

	<br />
  

		<p>
			Voici une méthode relativement "simple" à comprendre mais aussi à apprendre pour résoudre le cube.
			En effet, cette méthode ne demande l'apprentissage que d'à peine une dizaine d'algorithmes assez simples à retenir.

			On va s'y prendre méthodiquement.
			
			Pour ceux qui pensent ne jamias y arriver, cette méthode est faite pour vous !<br />
			Dans une centaine de mouvements des faces, le cube sera comme au premier jour, prêt à être mélangé à nouveau !
			Au pire, vous pourrez toujours demander au programme "Rubik Teacher" de trouver la solution à votre place !

			Par contre, ne vous attendez pas à pulvériser les records avec cette méthode. Simple, elle n'en reste pas moins longue ! 
			N'espérez que difficilement descendre en-dessous d'une minute pour la résolution du cube.
			Pour des résultats plus convaincants, et avec beaucoup de persévérance, vous pourrez ensuite vous frotter à la méthode <a href="/Rubik/Fridrich">Fridrich</a>.
		</p>

		

		<div class="puce">
			<p>
				L'idée de cette méthode de résolution (comme la plupart d'ailleurs...) consiste à résoudre le cube couche par couche, comme son nom l'indique.
				Le cube a 3 couches, donc on résoud d'abord une couche (une face en fait), puis la couronne centrale, pour finir avec la dernière face.
				Cette méthode se décompose en 5 étapes :
				<ul>
				   <li><a href="#croix1">Croix de la première face</a></li>
				   <li><a href="#f1l">Première Face</a></li>
				   <li><a href="#f2l">Couronne centrale</a></li>
				   <li><a href="#croix2">Croix de la dernière face</a></li>
				   <li><a href="#fin">Orientation des derniers coins</a></li>
				</ul>
			</p>
		</div>
        <br />
		
		Avant de commencer, il y a quelques points INDISPENSABLES à bien comprendre :
		<ul>
			<li>
				Sachez que la couleur située sur la pièce centrale d'une face correspond à LA couleur de cette face (en effet, puisque cette pièce ne peut être déplacée !)<br />			
				Pour la résolution de la première face, je vous conseille de choisir une couleur et de toujours commencer par cette même couleur :<br />
				Vous verrez qu'on s'habitue très vite à une couleur et qu'on le résoud ainsi bien plus vite.
			</li>
			<li>
				Faites bien la distinction entre toutes les pièces qui constituent le cube ;
				<ul>
					<li>les centres des faces : au nombre de 6, ils sont immobiles</li>
					<li>les bords : on en compte 12 et ont 2 couleurs</li>
					<li>les coins : il y en a 8 et ont 3 couleurs</li>
				</ul>
				Comprenez bien que leurs places ne sont pas échangeables : un coin ne peut pas prendre la place d'une arête, et inversement.
			</li>
		</ul>			

		Allez, c'est parti poour la résolution!

		<table>
		   <tr>
			   <td>
			   <br />
					<h2 id="croix1">1 Croix de la première face</h2>

					<p class="description">						
						La première étape consiste à construire une croix orientée sur la face supérieure du cube. 
						Orientée signifie que les 4 pièces (bords) que constituent la croix ont leurs 2 couleurs coïncidant avec les centres des faces adjacentes...
						Le but est donc d'obtenir la même configuration que sur l'image ci-contre (avec vos couleurs à vous).
						Ici, vous constatez que la croix bleue est faite sur la face de centre bleue et les couleurs des 4 arêtes correspondent bien avec les centres des faces voisines.
						
						Je ne peux que vous conseiller de comprendre par vous-même comment faire.<br />
						Vous allez surement galérer au début mais vous allez vite vous rendre compte par vous-même que ca n'est pas si compliqué.<br />
						Au pire, aidez-vous des quelques algorithmes pour résoudre la croix ou observez comment le programme s'y prend pour la résoudre.
					</p>
				</td>
				<td>
					<p>
						<img src={L_01} width="250px" alt="Cube" />
					</p>
				</td>
		   </tr>
		   
			<tr>
			   <td>
					<h2 id="f1l">2 Première Face</h2>
					<p class="description">
						Une fois les arêtes de la première face placées, profitons-en pour terminer la première face.
						Commencez par repérer un coin de cette face : une des trois couleurs du coin doit être la même que celle de la face à résoudre, les deux autres couleurs vous indiquant l'emplacement du coin.
						Une fois un coin trouvé et sa destination, de la même facon, trouvez le moyen par vous-même pour remonter le coin, orienté, sur la tranche supèrieure, sans casser la croix reconstituée lors de la première étape.
						Répétez cette opération pour 3 des 4 coins de la face supérieure... inutile de résoudre le dernier, cet emplacement libre nous sera utile dans l'étape suivante.
					</p>
				</td>
				<td>
					<p>
						<img src={L_02} width="250px" alt="Cube" />
					</p>
				</td>
		   </tr>
		   
		   	<tr>
			   <td>
					<h2 id="f2l">3/ F2L</h2>
					<p class="description">
						<h3>4.1/ Couronne centrale</h3>
						Comme le laisse supposer le nom de la méthode, la résolution du cube se fera couche par couche !
						On vient de terminer la première (ou presque), attaquons-nous maintenant à la seconde : la couronne centrale.
						Pour cela, on se sert de l'emplacement du dernier coin non résolu de la première face.
						Commencez par repérer un bord de la couronne centrale sur la face inférieure ainsi que son emplacement désiré sur la couronne centrale (les deux couleurs du bord doivent coincider avec les couleurs des centres ds faces).
						Tournez ensuite la face du haut (avec des mouvemnts U) pour amener le coin non résolu de la première face au-dessus de l'emplacement du bord de l'arête centrale.
						Vous vous retrouverez alors dans une des 2 situations suivantes. Utilisez l'algorithme approprié pour résoudre alors le bord.


        				<Stack direction="row" spacing={2}>		
							<div className="roofpig" data-config="alg=R' D' R| flags=showalg">1</div>
							<div className="roofpig" data-config="alg=F D F'| flags=showalg">1</div>
						</Stack>

						
							
						<div>
						Appliquer cette même méthode pour résoudre les 4 coins et terminer ainsi la couronne centrale.
						Attention ! Si jamais un des bors ne se trouve pas sur la face inférieure mais sur la couronne centrale, mal positionné ou mal orienté, l'utilisation d'un des 2 algorithmes précédents permet de descendre le coin sur la face D.
</div>



						<h3>4.2/ Résolution du dernier coin de la première face</h3>

						Maintenant, on va résoudre le dernier coin de la première face sans casser le reste, l'occasion de découvrir enfin le premier "vrai" algorithme.
						Pour cela, amenez le coin restant dans une des deux configurations suivantes avec des mouvements D. (Si le coin est dans une autre position/orientation, il faut arriver à le déloger en utilisant un des deux algorithmes)


						<Stack direction="row" spacing={2}>		
							<div class="roofpig" data-config="alg=R' D2 B' D2 B R| flags=showalg | colored=D-">1er cas</div>
							<div class="roofpig" data-config="alg=F D2 L D2 L' F'| flags=showalg | colored=D-">2ème cas</div>
						</Stack>
					</p>
				</td>
				<td>
					<p>
						<img src={L_03} width="250px" alt="Cube" />
					</p>
				</td>
		   </tr>
		   
		   	<tr>
			   <td>
					<h2 id="croix2">4/ Croix de la dernière face</h2>
					<p class="description">


						<h3>4.1/ Croix non orientée</h3>
						Les deux tiers du cube sont maintenant finis. Tournez le cube de 180 pour avoir la face terminée tournée vers le bas.
						Le plus dur va maintenant être de finir la dernière face (celle du dessus maintenant) sans casser ce qui vient d'être fait.
						On commence par faire une croix non orientée sur la face du haut.
						On peut se retrouver dans l'une des 4 situations suivantes, de la meilleure à la plus défavorable. Aplliquez alors l'algorithme dédié :
						{/* <ul>
							<li>La croix est déjà faite : coup de chance, on peut passer à l'étape suivante</li>
							<li>Forme I : algorithme <strong></strong></li>
							<li class="roofpig" data-config="alg=B L U L' U' B'| flags=showalg">1</li>
							<li>Forme L : algorithme <strong></strong></li>
							<li class="roofpig" data-config="alg=B U L U' L' B'| flags=showalg">1</li>
							<li>Autrement, si aucune arête n'est en place, faites un des deux algorithmes précédent pour vous retrouver dans une des deux configurations I ou L</li>
						</ul> */}



						<Stack direction="row" spacing={2}>		
							<div class="roofpig" data-config="alg=B L U L' U' B'| flags=showalg">1</div>
							<div class="roofpig" data-config="alg=B U L U' L' B'| flags=showalg">1</div>
						</Stack>


						<h3>4.2/ Orientation de la croix</h3>
							Maintenant on va orienter la croix : faire coincider les couleurs des bords avec les centres des faces voisines.
							En tournant la face du haut avec des mouvements U, on se retrouve dans l'une des configurations suivantes :
						<ul>
							<li>La croix est déjà orientée...</li>
							<li>1 arête est bien orientée, les 3 autres doivent être déplacées dans le sens horaire : algorithme <strong></strong></li>
							<li class="roofpig" data-config="alg=L' U2 L U L' U L| flags=showalg">1</li>
							<li>1 arête est bien orientée, les 3 autres doivent être déplacées dans le sens anti-horaire : algorithme <strong></strong></li>
							<li class="roofpig" data-config="alg=R U2 R' U' R U' R'| flags=showalg">1</li>
							<li>2 arêtes sont bien orientées : faites un des deux algorithmes précédent pour vous retrouver dans l'une des deux configurations</li>
						</ul>
					</p>
				</td>
				<td>
					<p>
						<img src={L_04} width="250px" alt="Cube" />
					</p>
				</td>
		   </tr>

		   	<tr>
			   <td>
					<h2 id="fin">5/ Résolution des derniers coins</h2>
					<p class="description">
						<h3>5.1/ Placement des coins</h3>
						On touche au but : plus que 4 coins à résoudre.
						Pour commencer, comprenez bien la différence entre un coin orienté et un coin placé : un coin orienté est un coin résolu, alors qu'un coin placé signifie que les trois couleurs qui le composent correspondent aux couleurs des trois faces voisines sans forcément être bien positionné. 
						Un coin placé peut ainsi avoir 3 orientations différentes, une seule étant correctement résolue...
						Passez en revue les 4 coins et comptez le nombre de coins bien placés (orientés ou non). 
						Dans l'idéal, vous en aurez 4 et vous pourrez passer à l'étape suivante.
						Si vous en avez un seul, appliquez l'algorithme qui vous permettra de placer tous les coins :
						<ul>
							<li class="roofpig" data-config="alg=L' U R U' L U R' U'| flags=showalg">1</li>

							<li class="roofpig" data-config="alg=z R U' L' U R' U' L U z'| flags=showalg">1</li>


						</ul>

						Si vous en avez 2, faites un des deux algorithmes précédent pour vous retrouver avec un seul coin placé.
						
						<h3>5.2) Orientation des coins</h3>
						Dernière étape... Le cube est quasiment terminé et, mauvaise nouvelle, on va tout casser !
						Mais si tout se passe bien, le cube devrait enfin être terminé à  la fin de cette étape délicate, qui, vous devez vous y attendre, ne fonctionnera peut-être pas du premier coup...
						Prenez le cube dans une position donnée, la face non résolue vers le haut, et à partir de maintenant, ne faites plus JAMAIS de rotation complète du cube (RX, RY, RZ) !
						Il s'agit maintenant de résoudre les 4 derniers coins de la face du haut (les tourner finalement). Avec un peu de chance, vous pouvez en avoir de déjà bien orientés.
						Peu importe, tous doivent être résolus de la même facon, l'un après l'autre. Pour cela, amener le premier coin à résoudre face à vous à l'emplacement dit "de résolution" (voir images ci-dessous) puis appliquez l'algorithme de résolution du coin ;

						<ul>
							<li>s'il est déjà orienté, passez directement au coin suivant : mouvements U pour amener le coin suivant à l'emplacement de résolution</li>
							<li class="roofpig" data-config="alg=F D' F' D F D' F' D| flags=showalg">1</li>
							<li class="roofpig" data-config="alg=D' F D F' D' F D F'| flags=showalg">1</li>
						</ul>
						"Heu... je viens de faire un algorithme, le coin est bien orienté maintenant... sauf que tout le reste est mélangé !" Pas de panique, j'avais prévenu.
						Pour les coins suivants, procédez de la même facon en amenant le prochain coin à l'emplacement de résolution avec des mouvements U et uniquement des mouvements U (U'à la limite !)
						Normalement, en répétant cette opération pour les 4 coins, le cube devrait normalement être totalement résolu.
						Dans le cas contraire, cliquez <a href="#croix1">ici</a>
						
						Félicitations ! Le cube est maintenant terminé !
					</p>
				</td>
				<td>
					<p>
						<img src={L_05} alt="Cube" />
					</p>
				</td>
		   </tr>
		</table>
    </div>
  );
}

export default RubikBeginner;
