
import React, { useEffect } from 'react';


import './Fridrich.css';

import Stack from "@mui/material/Stack"

//import useScript from './../customHooks'



function useScript(url) {
  useEffect(() => {
    //console.log(url);
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.type = "text/javascript"

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, [url]);
};


function RubikFridrich_OLL() {
  useScript("http://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js")
  useScript("http://www.recubix.fr/backup/Rubik/Fridrich/roofpig_and_three.min.js")

    return (

      <div>

        <h1>OLL</h1>		
      
        <h2>T shape</h2>
        <Stack direction="row" spacing={2}>		
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U' F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' R' F R F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
        </Stack>


        <h2>P shape</h2>
        <Stack direction="row" spacing={2}>		
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L d R' d' L' U L F L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' d' L d R U' R' F' R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L U y' R U' R' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
        </Stack>

        <Stack direction="row" spacing={2}>		
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' y L' U L F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
        </Stack>



<h2>C shape</h2>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U x' R U' R' U x U' R'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' x D' R' U R E' z'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>

<h2>S shape</h2>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R U R' U' y L' d R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F' L' U' L F d' R d L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>



<h2>W shape</h2>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L U L' U L U' L' U' y2 R' F R F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' R U' R' U R U y F R' F' R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>



<h2>I shape</h2>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U' y R2 D R' U2 R D' R2 d R'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' y' R' U R U' R' U R x' U x| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' y L' U L' y' L F L' F R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' B' L U' R' U R U' R' U R L' B L| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
</Stack>



<h2>Big L shape</h2>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R U R' F' R y L U' L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F' L' U' L F L' y' R' U R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R B R' L U L' U' R B' R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' B' L R' U' R U L' B L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>


<h2>Little L shape</h2>


<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U' R U R' U' F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F' L' U' L U L' U' L U F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L U' y' R' U2 R' U R U' R U2 R d' L' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>
<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R' F' R2 U2 x' U' R U R' x | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R F' U2 R2 y R' F' R F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F' L' F U2 L2 y' L F L' F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>




<h2>Squares</h2>


<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U2 R' U' R U' r'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>



<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U2 R U R' U r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>







<h2>Fishes</h2>

<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U2 l R U' R' U l' U2 R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=x' R U' R' U y' R U R' U'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' R y' x' R U' R' F R U R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>


<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' y R' F R U' R' F' R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>




<h2>Lightning</h2>
<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F R' F R F2 L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' B' L U' R' U R L' B L| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U2 r R2 U' R U' R' U2 R U' M | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U2 l' L2 U L' U L U2 L' U M | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>




<h2>H shape</h2>

<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=x' U' R U' R2 F x R U R' U' R B2| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R2 U R' B R U' R2 U l U l'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U' R U2 R' U' R U' R2 y' R' U' R U B | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>

<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U' R' U2 R U R' U R2 y R U R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>

</Stack>


<h2>Corners OK</h2>

<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=M' U2 M U2 M' U M U2 M' U2 M| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/216</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' R U R' U' L R' F R F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=M' U' M U2 M' U' M | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
</Stack>




<h2>Points</h2>

<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U B' l U l2' x' U' R' F R F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R F' U2 R' F R y' R2 U2 R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">2</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=y L' R2 B R' B L U2' L' B M' x' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">3</div>
</Stack>


<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U2 x R' U R U' y R' U' R' U R' F z' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">4</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U2 F R U R' U' y' R2 U2 x' R U| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">2</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U y' R' U2 x R' U R U' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">3</div>


</Stack>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U R' F R F' U2 R' F R F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">4</div>

</Stack>



<h2>Plus +</h2>

<Stack direction="row" spacing={2}>		

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R' U' R U R' U' R U' R'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R2 U' R2 U' R2 U2 R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">2</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L2 D' L U2 L' D L U2 L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">3</div>
</Stack>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F' L F R F' L' F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">4</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F' L' F R y' R' F R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">2</div>

<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' U R U' L U R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">3</div>
</Stack>

<Stack direction="row" spacing={2}>		
<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U' L' U R' U' L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">4</div>
</Stack>



  
      </div>
  
    );
  }
  
  export default RubikFridrich_OLL;