
import React, { useEffect } from 'react';


import './Fridrich.css';

import Stack from "@mui/material/Stack"



//import useScript from './../customHooks'



function useScript(url) {
  useEffect(() => {
    //console.log(url);
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.type = "text/javascript"

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, [url]);
};


function RubikFridrich_PLL() {
  useScript("http://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js")
  useScript("http://www.recubix.fr/backup/Rubik/Fridrich/roofpig_and_three.min.js")

    return (

      <div>
        


        <h1>PLL</h1>		
          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=B2 U L' R B2 L R' U B2| flags=showalg">1</div>
            <div class="roofpig" data-config="alg=B2 U' L' R B2 L R' U' B2| flags=showalg">2</div>
            <div class="roofpig" data-config="alg=x R' U R' D2 R U' R' D2 R2| flags=showalg">3</div>
          </Stack>
									
          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=x L U' L D2 L' U L D2 L2| flags=showalg">4</div>
            <div class="roofpig" data-config="alg=x' R U' R' U D R' D U' R' U R D2 F| flags=showalg">5</div>				
            <div class="roofpig" data-config="alg=x' R2 U' R x D2 r' D r D2 x' R D R U R' D'| flags=showalg">6</div>
          </Stack>
					
          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=R U R' U' R' F R2 U' R' U' R U R' F'| flags=showalg">7</div>	
            <div class="roofpig" data-config="alg=U' L2 F' L' U' L' U L F L' U2 L U2 L'| flags=showalg">8</div>
            <div class="roofpig" data-config="alg=U R2 F R U R U' R' F' R U2 R' U2 R| flags=showalg">9</div>				
          </Stack>
					
          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=L' U R U' L U2 R' U R U2 R'| flags=showalg">10</div>
            <div class="roofpig" data-config="alg=R U' L' U R' U2 L U' L' U2 L| flags=showalg">11</div>		
            <div class="roofpig" data-config="alg=R U' L d2 L' U L R' U' R U2 r' F l' U'| flags=showalg">12</div>	
          </Stack>

          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=L' U R' U2 L U' L' R U R' U2 L U' R U| flags=showalg">13</div>	
            <div class="roofpig" data-config="alg=L' U R U' L U L' U R' U' L U2 R U2 R' | flags=showalg">14</div>	
            <div class="roofpig" data-config="alg=F R U' R' U' R U R' F' R U R' U' R' F R F'| flags=showalg">15</div>	
          </Stack>

          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=F R' F' L F R F' L2 x' U' R U L U' R' U| flags=showalg">16</div>	
            <div class="roofpig" data-config="alg=M2 U M2 U2 M2 U M2| flags=showalg">17</div>	
            <div class="roofpig" data-config="alg=L2 u L' U L' U' L u' r2 y' R' D R| flags=showalg">18</div>	
          </Stack>
					
          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=R2 u' R U' R U R' u l2 y' x2 R U' R'| flags=showalg">19</div>	
            <div class="roofpig" data-config="alg=R U R' y' R2 u' R U' R' U R' u R2| flags=showalg">20</div>	
            <div class="roofpig" data-config="alg=L' U' L y' R2 u R' U R U' R u' R2 | flags=showalg">21</div>	
          </Stack>
</div>


    );
  }
  
  export default RubikFridrich_PLL;