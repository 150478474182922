import StackmatTimerImg from './img/virtualStackmat.jpg'

import Stack from "@mui/material/Stack"

import Button from '../Components/Button/Button'

import  VirtualStackmatTimer_app from "./Virtual_StackMat_Timer_v1_1_Setup.zip"


function VirtualStackmatTimer() {
  return (
  <div>
    <h1 >Virtual Stackmat</h1>
    <p>
        <b>
        <em>Virtual Stackmat</em> permet de chronométrer son temps.
        Le Stackmat timer est utilisé en compétition de Rubik's Cube notamment.
        </b>
    </p>

    <div >
    <Stack direction="column"   alignItems="center" justifyContent="center" spacing={2}>
      <Button title="Télécharger Virtual Stackmat Timer" url={ VirtualStackmatTimer_app } />
      <div style={{textAlign:'center'}}><a href={StackmatTimerImg}><img src={StackmatTimerImg} width="100%"/></a></div>
    </Stack>
  </div>
    
        <div>
            <p>Précisez votre pseudo avant de lancer le chrono.</p>
            <p>Pour démarrer le chronomètre:
                <ul>
                    <li>appuyez sur le bouton <b>Alt</b> (à gauche de la barre Espace) et <b>Ctrl</b> (à droite de la barre Espace): le voyant rouge s'allume.</li>
                    <li>Laissez appuyer 2 secondes, jusqu'à ce que le voyant verts'allume à son tour: le chonomètre est ainsi amorcé.</li>
                    <li>Maintenant, le chronomètre se déclenchera dès que l'une des 2 touches sera relâchée.</li>
                </ul>
            </p>
            <p>Pour arrêter le chronomètre, appuyez sur la barre <b>Espace</b>.</p>
            <p>Le logiciel classe ensuite automatiquement les temps et calcule la moyenne de chaque joueur.</p>
            
        </div>
    </div>
  );
}

export default VirtualStackmatTimer;
