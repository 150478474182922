import "./menu.css"


import HomeIcon from "./img/home.png"
import SoftwareIcon from "./img/software.png"
import RubikIcon from "./img/rubik.png"
import ContactIcon from "./img/contact.png"
import LinkIcon from './img/link.png'

import MailIcon from "./img/mail.png"
import LinkedInIcon from "./img/linkedin.png"


import Stack from "@mui/material/Stack"

// import TaskyIcon from "../Tasky/img/tasky.png"
// import RubikTeacherIcon from "../RubikTeacher/img/RubikTeacher.png"
// import StackmatTimerIcon from "../VirtualStackmatTimer/img/virtualStackmat2.png"

function Menu() {
  return (
    <nav className="menu" id="menu-accordeon">
        <ul>
          <li>
            <a  href="/">
              <Stack direction="row"  alignItems="center" spacing={2}>		
                <img src={HomeIcon} />
                <div>Accueil</div>
              </Stack>
            </a>
          </li>

          
          <li>
            <a  href="/">
            <Stack direction="row"  alignItems="center" spacing={2}>		
              <img src={SoftwareIcon} />
              Mes Logiciels
            </Stack>
            </a>
            <ul>
              <li><em>Organisation</em></li>
              <li><a href="/Tasky">Tasky</a></li>
              <li><em>Rubik's Cube</em></li>
              <li><a href="/RubikTeacher">Rubik Teacher</a></li>
              <li><a href="/VirtualStackmatTimer">Virtual Stackmat Timer</a></li>
            </ul>
          </li>

          <li>
            <Stack direction="row"  alignItems="center" spacing={2}>		
              <img src={RubikIcon} />
              <a  href="/Rubik">Résolution du Rubik's Cube</a>
            </Stack>
            <ul>
              <li><a href="/RubikBeginner">Couche par Couche (débutant)</a></li>
              <li><a href="/RubikFridrich">Fridrich (expert)</a></li>
            </ul>
          </li>

        { /*  <li>
            <a  href="/Links">
              <Stack direction="row"  alignItems="center" spacing={2}>		
                <img src={LinkIcon} />
                <div>Liens utiles</div>
              </Stack>
            </a>
          </li>*/ }


          <li>
            <Stack direction="row"  alignItems="center" spacing={2}>		
              <img src={ContactIcon} />
              <a  href="/">Contact</a>
            </Stack>
            <ul>
              <li>            
                <Stack direction="row"  alignItems="center" spacing={2}>		
                  <img src={MailIcon} />
                  <a  href="mailto:sebastien.labonne@gmail.com">Envoyez-moi un email</a>
                </Stack>
              </li>
                
              <li>            
                <Stack direction="row"  alignItems="center" spacing={2}>		
                  <img src={LinkedInIcon} />
                  <a  href="https://www.linkedin.com/in/slabonne/">LinkedIn</a>
                </Stack>
              </li>
            </ul>
         </li>
        </ul>
    </nav>
  );
}

export default Menu;
