import './App.css';

import { useEffect } from 'react';

import {Routes, Route} from "react-router-dom"

import { Helmet } from 'react-helmet';

import Menu from "./Menu/menu"
import Banner from "./Banner/Banner"
// import PageTemplate from './PageTemplate'

import HomePage from "./HomePage/HomePage"

import Tasky from "./Tasky/Tasky"
import RubikTeacher from "./RubikTeacher/rubikTeacher"
import VirtualStackmatTimer from "./VirtualStackmatTimer/VirtualStackmatTimer"

import RubikBeginner from "./RubikBeginner/Beginner"
import RubikFridrich from "./RubikFridrich/Fridrich"
import RubikFridrich_OLL from "./RubikFridrich/OLL"
import RubikFridrich_PLL from "./RubikFridrich/PLL"

import Links from './Links/Links'


function App() {

  return (

    <div className="App">
       <Helmet>
          <title>Bienvenue sur recubix.fr</title>
        </Helmet>
      <Menu />
      <div className="body">
        <Banner />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/Tasky' element={<Tasky />} />
          <Route path='/RubikTeacher' element={<RubikTeacher />} /> 
          <Route path='/VirtualStackmatTimer' element={<VirtualStackmatTimer />} /> 

          <Route path='/RubikBeginner' element={<RubikBeginner/>} /> 
          <Route path='/RubikFridrich' element={<RubikFridrich/>} /> 
            <Route path='/RubikFridrich_OLL' element={<RubikFridrich_OLL/>} /> 
            <Route path='/RubikFridrich_PLL' element={<RubikFridrich_PLL/>} /> 

            <Route path='/Links' element={<Links/>} /> 
        </Routes>
      </div>
    </div>

  );
}

export default App;
