import "./tasky.css"

import TaskyImg from "./img/Tasky1.png"
import TaskyImg2 from "./img/Tasky2.png"

import Stack from "@mui/material/Stack"

import Button from '../Components/Button/Button'



import React from 'react';
import { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'

import  Tasky_1_5 from "./Tasky_1.5_Setup.zip"

import imlg1 from "./img/Tasky_1_TODO.png";
import imlg2 from "./img/Tasky_2_CONTACTS.png";
import imlg3 from "./img/Tasky_3_WORK.png";
import imlg4 from "./img/Tasky_4_Notes.png";
import imlg5 from "./img/Tasky_5_Links.png";
import imlg6 from "./img/Tasky_6_Purchases.png";

function Example(props)
{
  // var imlg1 = require("./img/Tasky_1_TODO.png");
  // var imlg2 = require("./img/Tasky_2_CONTACTS.png");

    var items = [
        {
          // name: "Random Name #1",
          // description: "Hello World!",
            img: imlg1,
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: imlg2
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: imlg3
        }
        ,
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: imlg4
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: imlg5
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: imlg6
        }
    ]

    const [firstImgLoaded, setFirstImgLoaded] = useState(false);

    return (
        // <Carousel fullWidth  >
        //     {
        //         items.map( (item, i) => <Item swipe={false} key={i} item={item} /> )
        //     }
        // </Carousel>

        // <Stack direction="column"   alignItems="center" justifyContent="center" spacing={2}>
          <div>
          <img
            src={imlg1}
            alt="imlg1"
            onLoad={() => setFirstImgLoaded(true)}
            style={{ display: "none" }}
          />
          {firstImgLoaded && (
            <Carousel autoPlay={true} interval={2000} >
              {items.map((item, i) => (
                <Item key={item.name} {...item} />
              ))}
            </Carousel>
          )}
        {/* </Stack> */}
        </div>
    )
}

function Item({ name, description, img }) {
  return (
    <div>
      <h2>{name}</h2>
      <p>{description}</p>
      <div  class="centered">
        <img src={img} alt="img" />
      </div>
    </div>
  )
}







function Tasky() {
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);


  return (
  <div>
    <h1>Tasky</h1>
    <p><b>Tasky est un outil d'organisation et de productivité qui permet de créer des listes de tâches de façon très simple et intuitive, mais pas que...</b></p>


    {/* <Stack direction="rows" >

  <div width="70%">
    <Stack direction="column" > */}
    <div>
    <Example  />
    </div>
      <Button title="Télécharger Tasky 1.5" url={Tasky_1_5} />
      {/* <div style={{textAlign:'center'}}><a href={TaskyImg}><img src={TaskyImg} width="70%"/></a></div> */}
      
{/*       
    
    </Stack>
  </div>
 */}


    <div >
      <p>
      Tasky permet de:
      </p>
      <div>
      <ul>
        <li>créer des tâches. Les différents types de tâches sont accessibles avec le clic droit de la souris:</li>
        <ul>
          <li><b>Tâches</b> à cocher</li>
          <li><b>Etiquettes</b>: texte non cochable</li>
          <li><b>Lien</b> (vers un site Internet, un email ou un fichier/dossier sur le PC)</li>
          <li><b>Liste de tâches</b>: lien vers une autre liste de tâches dans Tasky</li>
          <li><b>Contact</b>: pour enregistrer un email, un téléphone, une adresse, rappeler un anniversaire...</li>
          <li><b>Script</b>: pour lancer rapidement des commandes Windows et automatiser des tâches</li>
        </ul>
        <br />
        <li>organiser les différentes tâches en lignes et en arbres de tâches</li>
        <br />
        <li>Associer à chaque tâche:</li>
        <ul>
          <li>un <b>memo</b> complémentaire</li>
          <li>des <b>liens</b> Internet, ou vers un fichier/dossier</li>
          <li>enregistrer un <b>mot de passe</b> (ou en générer un de façon aléatoire grâce au générateur inclus)</li>
          <li>programmer un <b>rappel</b></li>
        </ul>
        <br />
        <li>personnaliser les tâches (couleur, gras, italique, couleur de fond)</li>
        <li>organiser les tâches en les déplaçant intuitivement avec la souris</li>
        <li>créer autant de listes que souhaité</li>
        <li>crypter des listes de tâches pour sécuriser vos mots de passe par exemple</li>
        <li>filter les tâches à afficher</li>
        <li>exporter les tâches vers le presse-papier ou les imprimer</li>
      </ul>
      </div>
    </div>
    {/* </Stack> */}

    <br /><br /><br /><br />

    <div>
      Ce logiciel peut ainsi répondre à de nombreux usages pour améliorer la productivité. Par exemple, vous pouvez l'utiliser pour:
      <ul>
        <li>créer des listes de tâches organisées par priorité</li>
        <li>vous rappeler des rendez-vous importants</li>
        <li>avoir un outil de prises de notes/memo</li>
        <li>avoir un gestionnaire de liens Internet favoris</li>
        <li>avoir un gestionnaire/générateur de mots de passe protégé</li>
        <li>avoir une liste de contacts avec des rappels d'anniversaire!</li>
        <li>créer une liste de courses à imprimer, avec les articles organisés par catégories/rayons pour gagner du temps en magasin!</li>
        <li>lancer des scripts simples pour effectuer des tâches répétitives rapidement</li>
      </ul>
    </div>




  </div>
  );
}

export default Tasky;
