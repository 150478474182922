import "./banner.css"
import banner from "./banner.jpg"

function Banner() {
  return (
    <div className="banner">
        <img  src={banner}  />
    </div>
  );
}

export default Banner;
